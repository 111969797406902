/*********************
TYPOGRAPHY
*********************/
@font-face {
   font-family: Poppins;
   font-weight: 100;
   font-style: normal;
   src: url('/wp-content/themes/SchraderGroup/assets/fonts/Poppins-Thin.ttf') format('truetype');
}

@font-face {
   font-family: Poppins;
   font-weight: 200;
   font-style: normal;
   src: url('/wp-content/themes/SchraderGroup/assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
   font-family: Poppins;
   font-weight: 300;
   font-style: normal;
   src: url('/wp-content/themes/SchraderGroup/assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
   font-family: Poppins;
   font-weight: 400;
   font-style: normal;
   src: url('/wp-content/themes/SchraderGroup/assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
   font-family: Poppins;
   font-weight: 500;
   font-style: normal;
   src: url('/wp-content/themes/SchraderGroup/assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
   font-family: Poppins;
   font-weight: 600;
   font-style: normal;
   src: url('/wp-content/themes/SchraderGroup/assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
   font-family: Poppins;
   font-weight: 700;
   font-style: normal;
   src: url('/wp-content/themes/SchraderGroup/assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
   font-family: Poppins;
   font-weight: 800;
   font-style: normal;
   src: url('/wp-content/themes/SchraderGroup/assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
   font-family: Poppins;
   font-weight: 900;
   font-style: normal;
   src: url('/wp-content/themes/SchraderGroup/assets/fonts/Poppins-Black.ttf') format('truetype');
}
