.front-content {
  .h3 {
    text-transform: uppercase;
    letter-spacing: 8px;
  }
}

.hero-scene {
  background-color: #13396D;
  background-blend-mode: luminosity;
  position: relative;

    h1 {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 8px;

      @media only screen and (min-width: 1024px) {
        font-size: 2.7rem;
      }
    }

    p {
      font-size: 1.5rem;
      font-weight: 300;
      letter-spacing: 4px;

      @media only screen and (min-width: 1024px) {
        font-size: 1.9rem;
      }
    }

    .gradient {
      padding-top: 100px;
      padding-bottom: 190px;

      @media only screen and (min-width: 1024px) {
        padding-top: 200px;
        padding-bottom: 290px;
      }
    }
}

.hero-search-container {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;

    .idx-omnibar-form button {
      width: fit-content;
      display: inline-block;
      background-color: orange;
      padding: 0px 40px;
      color: #fff;
    }

    .idx-omnibar-form {
      text-align: center;
    }

    .search-bar {
      background-color: #fff;
    }

    @media only screen and (min-width: 1027px) {
      .widget div.awesomplete {
        width: 84%;
      }

      .idx-omnibar-form {
        text-align: left;
      }
    }

    span.hero-buttons {
      padding: 9px 10px;
      display: inline-block;
      background-color: $orange;
      margin: 5px 20px;
      position: relative;

        &:last-of-type {
          margin: 0 20px 0 0;
        }

        &:before {
          border-bottom: 42px solid $orange;
          border-left: 20px solid transparent;
          content: '';
          position: absolute;
          top: 0;
          right: 100%;
          height: 100%;
          width: 20px;
        }

        &:after {
          border-right: 20px solid transparent;
          border-top: 42px solid $orange;
          content: '';
          position: absolute;
          top: 0;
          left: 100%;
          height: 100%;
          width: 20px;
        }


        &.team-link {
          background-color: $white;
            a {
              color: $blue;
            }

            &:before {
              border-bottom: 42px solid $white;
            }

            &:after {
              border-top: 42px solid $white;
            }
        }

    }
}

a.advanced-search {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 30px;
}

.hero-video-button {
  text-transform: uppercase;
  opacity: .5;
  position: absolute;
  font-size: 2rem;
  font-weight: 300;
  right: 0;
  left: 0;
  bottom: 50px;

  img {
    max-height: 50px;

    margin-right: 30px;
  }
}

// Featured property

.featured-properties-container{
  padding-top: 100px;
  padding-bottom: 80px;

    .h3 {
      margin-bottom: 45px;

      @media only screen and (min-width: 1024px) {
        margin-bottom: 115px;
      }
    }

    i.fas.fa-chevron-left.slick-arrow, i.fas.fa-chevron-right.slick-arrow {
      background-color: $medium-gray;
      color: #fefefe;
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
      right: 100%;
      text-align: center;
      z-index: 1;
      transition: all .3s ease;

        &:hover {
          background-color: $orange;
        }
    }

    i.fas.fa-chevron-right.slick-arrow  {
      left: 100%;
    }

    i:before {
      top: 29%;
      position: relative;
    }
}

.featured-property {
  padding-left: 20px;
  padding-right: 20px;
}

.property {
  height: 300px;
  position: relative;
  overflow: hidden;

    .overlay {
      position: absolute;
      top: 1000px;
      transition: all .3s ease;
      height: 100%;
      width: 100%;
      padding: 60px 30px 0px 30px;
    }

    .prop-name {
      text-transform: uppercase;
      font-size: 1.7rem;
      font-weight: 600;
      letter-spacing: 4px;
    }

    p {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 35px;
    }

}

.property-link {
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  height: 40px;
  border-top: 5px solid $blue;
  width: fit-content;

    a {
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 6px;
      padding: 5px 10px;
      display: inline-block;
    }

    .link-overlay {
      position: absolute;
      bottom: -1000px;
      transition: all .3s ease;
      padding: 5px 10px;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 4px;
    }
}

.featured-property:hover {
  .property .overlay {
    transition: all .3s ease;
    top: 0px;
  }

  .property-link {
    width: 100%;
  }

  .property-link .link-overlay {
    top: 0px;
    height: 100%;
    transition: all .3s ease;
    background-color: $white;
    color: $blue;
    width: 100%;
    border-left: 1px solid $dark-gray;
    border-bottom: 1px solid $dark-gray;

        i.fas.fa-chevron-right {
    	    background-color: $blue;
          position: absolute;
          right: 0;
          width: 40px;
          height: 100%;
          top: 0px;
          color: $white;
          text-align: center;
    		}

    		.fa-chevron-right:before {
    	    content: "\f054";
    	    position: relative;
    	    top: 7px;
    		}
  }
}

.h5.prop-name:before {
    content: '';
    width: 100px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 30px;
}

// Valuation section

.valuation-container {
  background-color: #EFEFEF;
  padding-top: 30px;
  padding-bottom: 30px;

  .h4 {
    text-transform: uppercase;
    letter-spacing: 6px;
  }
}

// About Container

.about-container {
  padding-top: 100px;
  padding-bottom: 100px;

    .image {
      height: 350px;
    }

    .h3, p {
      margin-bottom: 30px;
    }
}

// Stat Container

.stat-container {
  background-image: url('/wp-content/themes/SchraderGroup/assets/images/OrangePattern.jpg');
  padding-top: 50px;
  padding-bottom: 70px;

    span.text {
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 3px;
    }
}

img.stat-icon {
  max-height: 70px;
  display: block;
  margin: 0 auto 30px auto;
}

.stat-number, .counter{
    font-size: 5rem;
    font-weight: 800;
    letter-spacing: 14px;
}

@media only screen and (max-width: 1024px) {
  .stat {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  img.stat-icon {
    max-height: 60px;
    margin: 0 auto 0px;
  }

  .stat-container span.text {
    font-size: 1rem;
    margin-bottom: 20px;
    display: inline-block;
  }

  .counter, .stat-number {
    line-height: 30px;
    font-size: 4rem;
    font-weight: 800;
    letter-spacing: -1px;
  }
}

// Team members

.team-member {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 65px;
  border-bottom: 1px solid transparent;
  transition: all .3s ease;

    &:hover {
      border-bottom: 1px solid $blue;
      transition: all .3s ease;
    }
}

.member-image {
  height: 300px;
}

.team-slider-container {
  padding-top: 80px;
  padding-bottom: 80px;

  .h4 {
    text-transform: uppercase;
    letter-spacing: 5px;
  }

  .h6 {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-style: italic;
    margin-bottom: 20px;
  }
}

.team-slider {
  padding-top: 80px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;

    .slick-slide {
        opacity: 0.4;
    }

    .slick-slide.slick-active {
        opacity: 1;
    }
}



.member-info {
  padding: 30px 20px 20px 20px;
  background-color: #efefef;

  .h6 {
    font-weight: 700;
    font-style: normal;
    text-transform: capitalize;
    letter-spacing: 3px;
    font-size: 1.6rem;
    margin-bottom: 6px;
  }

  p {
    font-size: 1.3rem;
    letter-spacing: 1px;
    margin-bottom: 0px;
    color: $blue;
  }
}

// Testimonials Container

.testimonials-container {
  padding-bottom: 80px;
    .h4 {
      text-transform: uppercase;
      letter-spacing: 5px;
    }

    i.fa.fa-arrow-right.slick-arrow {
      background-color: $blue;
      color: $white;
      position: absolute;
      right: 35%;
      width: 40px;
      height: 40px;
      top: 10px;
      text-align: center;

      @media only screen and (min-width: 1024px) {
        top: -40px;
        right: 0;
      }

    }

    i.fa.fa-arrow-left.slick-arrow {
      background-color: $blue;
      color: $white;
      position: absolute;
      top: 10px;
      width: 40px;
      height: 40px;
      left: 35%;
      text-align: center;
      z-index: 1;

      @media only screen and (min-width: 1024px) {
        top: -40px;
        right: 80px;
        left: auto;
      }
    }

    .fa-arrow-right.slick-arrow:before {
      content: "\f054";
      position: relative;
      top: 31%;
    }

    .fa-arrow-left.slick-arrow:before {
      content: "\f053";
      position: relative;
      top: 31%;
    }
}

.video-testimonial, .text-testimonial {
  padding-top: 30px;
}

.text-testimonial {
    padding: 30px 20px;

    p {
      margin-bottom: 0px;
      font-size: 1.4rem;

      @media only screen and (max-width: 1024px) {
        margin-top: 60px;
      }
    }

    span {
      text-transform: capitalize;
      padding: 10px;
      font-size: 1.3rem;
      font-weight: 500;
    }
}

.testimonial-image {
  margin-top: 40px;
  height: 80px;
  width: 80px;
  content: "";
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}

.video-testimonial {
  div {
    text-transform: capitalize;
    padding: 10px;
    font-size: 1.3rem;
    font-weight: 500;
  }
}

// highlights-container

.highlights-container {
  padding-top: 100px;
  border-top: 1px solid #e0e0e0;
  margin-bottom: 40px;
}

.feature {
  padding-right: 1px;
}

.h3.highlights-title {
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    padding: 10px;
    text-transform: uppercase;
    letter-spacing: 8px;
}

.highlight-image {
  height: 350px;
  position: relative;
}

.highlight-info {
  background-color: rgba(19, 57, 109, 0.85);
  padding-top: 30px;
  padding-bottom: 30px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 100%;
  height: 55%;

    .text {
      @media only screen and (min-width: 1024px) {
        top: 50% !important;
        transform: translateY(-50%);
      }
      position: relative;
      width: 100%;
      padding: 0 10px;
      text-transform: uppercase;
    }

    .h4 {
      font-weight: 700;
      font-size: 1.7rem;
      letter-spacing: 6px;
      line-height: 46px;
    }
}

.cat-name {
    color: $blue;
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 6px;
}
