/************************************************

Stylesheet: Global Stylesheet

*************************************************/

/*********************
HEADER
*********************/

img.header-logo {
  max-height: 60px;
}

.top-bar .dropdown.menu a {
    padding: 3.5rem 2rem;
    font-size: 1.2rem;
    letter-spacing: 2px;
    color: $blue;
}

.mgit {
  padding: 2.5rem 1rem;
}

.mgit img {
  max-height: 21px;

  @media only screen and (min-width: 1400px) {
    display: none;
  }
}

a.language-button {
  position: relative;

    i {
      font-size: 2rem;
      height: 100%;

        &:before   {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
    }
}

.get-in-touch {
  display: none;
}

@media only screen and (min-width: 1024px) and (max-width: 1151px) {
  .top-bar .dropdown.menu a {
    padding: 3.5rem 1rem;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1151px) and (max-width: 11400px) {
  .top-bar .dropdown.menu a {
    padding: 3.5rem 1.6rem;
  }
}

@media only screen and (min-width: 1400px){
    header.header {
        position: relative;
    }

    .get-in-touch {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 80px;
        background-color: $white;
        height: 100vh;
        z-index: 1;
    }

    .get-in-touch img {
      max-height: 26px;
      position: absolute;
      top: 0px;
      right: 30px;
      margin-top: 3.5rem;
      margin-bottom: 3.5rem;
    }

    .git-label {
      right: 29px;
      position: relative;
      transform: rotate(90deg);
      width: max-content;
      text-transform: uppercase;
      top: 240px;

        &:before {
          height: 40px;
          width: 1px;
          background-color: #13396d;
          position: absolute;
          content: "";
          top: -8px;
          z-index: 2;
          left: -40px;
          transform: rotate(90deg);
        }
    }

    .restrict-width {
      margin: auto 40px;
      max-width: 146rem;
    }
}

.get-in-touch-popup {
  display: none;
  width: 70%;
  position: fixed;
  top: 45px;
  right: 30px;
  background-color: #e5e5e5;
  padding: 40px 60px;
  z-index: 1;
  max-height: 100%;
  overflow: scroll;

    .h3 {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 10px;
    }

    p {
      font-weight: 500;
      font-size: 1.1rem;
      margin-bottom: 20px;
    }

    .contact-content .phone-numbers a {
      font-size: 1.5rem;
    }

    input, li, textarea {
        margin-bottom: 0px;
        margin-top: 0px !important;
        border: none;
    }

    .gform_footer.top_label {
      padding-top: 0px;
      margin-top: 0px;
      text-align: center;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1400px) {
      // top: 77px;
      right: 19px;
    }
}

li#field_4_6 {
    display: inline-block;
    width: 50%;
    top: -39px;
    position: relative;
}

img.close {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    padding-bottom: 10px;
    padding-left: 10px;
    max-height: 36px;
}

.is-active {
  display: block;
}

/*********************
HERO STYLES
*********************/

.inner-hero {
	.gradient {
    padding-top: 120px;
    padding-bottom: 35px;
	}

	.h1 {
    font-size: 2.4rem;
    text-transform: uppercase;
    letter-spacing: 6px;

		span {
			font-weight: 700;
		}
	}
}

/*********************
POSTS & CONTENT STYLES
*********************/

body {
	font-size: 16px;
}

.inner-content {
  padding-top: 80px;
}

div, p {
	letter-spacing: 2px;
}

a {
  transition: all .3s ease;

    &:hover {
      transition: all .3s ease;
    }
}

.inner-content hr {
	max-width: 100%;
	padding-left: 0px;
	padding-right: 0px;
}

blockquote {
    text-align: center;
    font-style: italic;
    font-weight: 600;
    max-width: 80%;
    margin: 0 auto;

			& p:before {
				content: "\201C";
				font-family: "Poppins";
			}

			& p:after {
				content: "\201D";
				font-family: "Poppins";
			}
}

.colored {
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, div, span, a {
		color: $white;
	}
}

.background {
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.gradient {
	background: -moz-linear-gradient(left, rgba(19,57,109,0.88) 0%, rgba(19,57,109,0.88) 34%, rgba(19,57,109,0.98) 91%, rgba(19,57,109,0.98) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(19,57,109,0.88) 0%,rgba(19,57,109,0.88) 34%,rgba(19,57,109,0.98) 91%,rgba(19,57,109,0.98) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(19,57,109,0.88) 0%,rgba(19,57,109,0.88) 34%,rgba(19,57,109,0.98) 91%,rgba(19,57,109,0.98) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e013396d', endColorstr='#fa13396d',GradientType=1 ); /* IE6-9 */
}

.button.primary, .button {
	background-color: #13396d;
	color: #fefefe;
	font-size: 1.3rem;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: 3px;
  padding-right: 15px;
	margin-top: 10px;
	margin-right: 40px;
	position: relative;

		&:hover {
			background-color: #F5821F;
		}

		i.fas.fa-chevron-right {
	    background-color: #F5821F;
	    position: absolute;
	    right: -40px;
	    width: 40px;
	    height: calc(100% + 2px);
	    top: -1px;
		}

		.fa-chevron-right:before {
	    content: "\f054";
	    position: relative;
	    top: 31%;
		}
}

.button.secondary {
	background-color: #F5821F;
		i.fas.fa-chevron-right {
			background-color: #13396d;
		}

		&:hover {
			background-color: #13396d;
		}
}

.button.ghost {
	background-color:  $white;
	color: $blue;
	margin-bottom: 0px;
	border-bottom: 1px solid $blue;

		i.fas.fa-chevron-right {
			background-color: $blue;
			color: $white;
		}

		&:hover {
			background-color: $blue;
			color: $white;
		}
}

.page-title {

	// Remove Foundation vcard styling from author page titles
	.vcard {
		border: 0px;
		padding: 0px;
	}
}

// Post meta
.byline {
	color: #999;
	time {}
}

// Entry content
.entry-content {

	img {
		max-width: 100%;
		height: auto;
	}

	.alignleft, img.alignleft {
		margin-right: 1.5em;
		display: inline;
		float: left;
	}
	.alignright, img.alignright {
		margin-left: 1.5em;
		display: inline;
		float: right;
	}
	.aligncenter, img.aligncenter {
		margin-right: auto;
		margin-left: auto;
		display: block;
		clear: both;
	}

	video, object {
		max-width: 100%;
		height: auto;
		}

	pre {
		background: #eee;
		border: 1px solid #cecece;
		padding: 10px;
		}

}

.wp-caption {
	max-width: 100%;
	background: #eee;
	padding: 5px;

	// Images inside wp-caption
	img {
		max-width: 100%;
		margin-bottom: 0;
		width: 100%;
	}

	p.wp-caption-text {
		font-size: 0.85em;
		margin: 4px 0 7px;
		text-align: center;
	}
}

// Add Foundation styling to password protect button
.post-password-form input[type="submit"] {
	@include button;
}

/*********************
IMAGE GALLERY STYLES
*********************/
.gallery {
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-right: -15px;
	margin-left: -15px;
}
.gallery::before, .gallery::after {
	content: ' ';
	display: table;
}
.gallery::after {
	clear: both;
}
.gallery.gallery-columns-1 > dl {
	@include grid-column;
}
.gallery.gallery-columns-2 > dl {
	@include grid-column(1 of 2);
}
.gallery.gallery-columns-3 > dl {
	@include grid-column(1 of 3);
}
.gallery.gallery-columns-4 > dl {
	@include grid-column(1 of 4);
}
.gallery.gallery-columns-5 > dl {
	@include grid-column(1 of 5);
}
.gallery.gallery-columns-6 > dl {
	@include grid-column(1 of 6);
}
.gallery.gallery-columns-7 > dl {
	@include grid-column(1 of 7);
}
.gallery.gallery-columns-8 > dl {
	@include grid-column(1 of 8);
}
.gallery dl {
	width: 100%;
	float: left;
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
}
.gallery dl:last-child:not(:first-child) {
  float: right;
 }

// Gallery caption styles
.gallery-caption {}
.size-full {}
.size-large {}
.size-medium {}
.size-thumbnail {}

/*********************
PAGE NAVI STYLES
*********************/
.page-navigation {
	margin-top: 1rem;
}

/*********************
COMMENT STYLES
*********************/
#comments {
	.commentlist {
		margin-left: 0px;
	}
}

#respond {
	ul {
		margin-left: 0px;
	}
}

.commentlist {
	li {
		position: relative;
		clear: both;
		overflow: hidden;
		list-style-type: none;
		margin-bottom: 1.5em;
		padding: 0.7335em 10px;

		&:last-child {
			margin-bottom: 0;
		}

		ul.children {
			margin: 0;

			li {}

			.comment {}
			.alt {}
			.odd {}
			.even {}
			.depth-1 {}           // Change number for different depth
			.byuser {}
			.bypostauthor {}
			.comment-author-admin {}

		}
	}

	li[class*=depth-] {
		margin-top: 1.1em;
	}

	li.depth-1 {
		margin-left: 0;
		margin-top: 0;
	}

	li:not(.depth-1) {
		margin-left: 10px;
		margin-top: 0;
		padding-bottom: 0;
	}

	li.depth-2 {}
	li.depth-3 {
	}
	li.depth-4 {}
	li.depth-5 {}

	// General comment classes
	.alt {}
	.odd {
	}
	.even {
	}
	.parent {}
	.comment {}
	.children {}
	.pingback {}
	.bypostauthor {}
	.comment-author {}
	.comment-author-admin {}
	.thread-alt {}
	.thread-odd {}
	.thread-even {}

	// vcard
	.vcard {
		margin-left: 50px;

		cite.fn {
			font-weight: 700;
			font-style: normal;

			a.url {}

		}

		time {
			float: right;

			a {
				color: #999;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}

			}

		}

		img.photo {}

		img.avatar {
			position: absolute;
			left: 10px;
			padding: 2px;
			border: 1px solid #cecece;
			background: #fff;
		}

	} // end .commentlist .vcard


	// comment meta
	.comment-meta {

		a {}

	}

	.commentmetadata {

		a {}

	}

	// comment content
	.comment_content {

		p {
			margin: 0.7335em 0 1.5em;
			font-size: 1em;
			line-height: 1.5em;
		}

	} // end .commentlist .comment_content

	// comment reply link
	.comment-reply-link {
		float: right;

		&:hover,
		&:focus {

		}

	} // end .commentlist .comment-reply-link

} // end .commentlist

/*********************
COMMENT FORM STYLES
*********************/
.respond-form {
	margin: 1.5em 10px;
	padding-bottom: 2.2em;

	form {
		margin: 0.75em 0;

		li {
			list-style-type: none;
			clear: both;
			margin-bottom: 0.7335em;

			label,
			small {
				display: none;
			}
		}

		input[type=text],
		input[type=email],
		input[type=url],
		textarea {
			padding: 3px 6px;
			background: #efefef;
			border: 2px solid #cecece;
			line-height: 1.5em;

			&:focus {
				background: #fff;
			}

			// form validation
			&:invalid {
				outline: none;
				border-color: #fbc2c4;
				background-color: #f6e7eb;
				-webkit-box-shadow: none;
				   -moz-box-shadow: none;
				    -ms-box-shadow: none;
						box-shadow: none;
			}
		}

		input[type=text],
		input[type=email],
		input[type=url] {
			max-width: 400px;
			min-width: 250px;
		}

		textarea {
			resize: none;
			width: 97.3%;
			height: 150px;
		}

	}
}

// comment form title
#comment-form-title {
	margin: 0 0 1.1em;
}

// cancel comment reply link
#cancel-comment-reply {
	a {}
}

// logged in comments
.comments-logged-in-as {}

// allowed tags
#allowed_tags {
	margin: 1.5em 10px 0.7335em 0;
}

// No comments
.nocomments {
	margin: 0 20px 1.1em;
}

/*********************
SIDEBARS & ASIDES
*********************/
.sidebar {
	li{
		list-style: none;
	}
}

.widget {
  margin-bottom: 40px;
  	ul {
  		margin: 0;
  	}
}

h4.widgettitle {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 3px;
    margin-bottom: 20px;
}
/*********************
FOOTER STYLES
*********************/
.footer {
	clear: both;
}

.inner-footer {
	.gradient {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.address, .email {
    line-height: 30px;
    font-size: 1.2rem;
	}



	.h5 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.3rem;
    letter-spacing: 1px;
	}

	.quick-links {
		ul#menu-quick-links {
    	display: block;
		}
		li {
			display: block;
		}
		a {
	    padding-left: 0px;
	    line-height: 25px;
	    font-size: 1.2rem;
	    letter-spacing: 1px;

        &:hover {
          color: $orange;
        }
		}
	}

	.social-container {
		margin-top: 30px;
    display: block;

      a {
        display: inline-block;
      }
			i {
				color: $orange;
				font-size: 1.8rem;
				margin-right: 30px;
			}
	}
}

.phone-numbers {
	a {
		background-color: #fff;
		color: $blue;
		padding-right: 10px;
		white-space: nowrap;
		margin: 5px 10px 5px 0px;
		display: inline-block;
		font-size: 1.2rem;
	}
	span {
		background-color: $orange;
		padding: 1px 3px;
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: 1px;
		margin-right: 5px;
	}
}

.copyright {
	background-color: #b7b7b7;
	padding-top: 5px;
	padding-bottom: 5px;
	 	p {
			color: $black;
			text-transform: uppercase;
			margin-bottom: 0;
    	letter-spacing: 1px;
		}
}

/*********************
VISUAL EDITOR STYLES
*********************/
// Makes content look better in visual editor
body#tinymce {
   	margin: 20px;
}

/*********************
PLUGIN STYLES
*********************/
// Makes Gravity Forms play nice with Foundation Form styles
.gform_body ul {
    list-style: none outside none;
    margin: 0;
}

h2 {
	a {
		color: green;
		font-size: 4rem;
	}

}
