/*********************
SINGLE BLOG
*********************/

.single-post-container {
  padding-bottom: 60px;
  // border-bottom: 1px solid #e0e0e0;

    img.wp-post-image {
      @media only screen and (min-width: 1260px) {
        margin-bottom: 80px;
      }
    }
}

.inner-hightlights {
  padding-bottom: 60px;
  border-top: 0px;
  border-top: 1px solid #e0e0e0;
}

.thumbnail-container {
    position: relative;

      p#share {
        color: $white;
        background-color: $orange;
        padding: 20px;
        bottom: 100px;
        text-transform: uppercase;
        text-align: center;

        @media only screen and (min-width: 1260px) {
          left: -60px;
          width: fit-content;
          position: absolute;

            &:before {
              content: "";
              position: absolute;
              border-bottom: 12px solid #C86917;
              border-left: 64px solid transparent;
              border-top: 22px solid transparent;
              bottom: 100%;
              left: 0;
              z-index: -1;
            }
        }
      }

      i {
        color: #fff;
        font-size: 2rem;
        margin: 0 1rem;
      }
}


p#share.no-thumb {
  position: relative;
  top: 0;
  width: 100%;
  left: 0;

    &:before {
      display: none;
    }
}


/*********************
ARCHIVE AND BLOG
*********************/
select#cat {
  width: 300px;
  text-transform: uppercase;
  color: $dark-gray;
  letter-spacing: 3px;
  margin-bottom: 40px;
}

.overlay {
    // opacity: 0;
    background-color: transparent;
    transition: all .3s ease;
    height: 100%;
}

.index-page .highlight-image {
  button {
    display: none;
    bottom: 10px;
  }

    &:hover {

      button {
        display: block;
        bottom: 20px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 50%;

          i {
            display: none;
          }
      }

      .overlay {
          opacity: 1;
          background-color: rgba(19,57,109,.85);
          transition: all .3s ease;
      }

      .highlight-info {
        background-color: transparent;

      }
    }
}

/*********************
ABOUT CONTAINER
*********************/

.about-container {
  padding-bottom: 60px;
}

.about-team {

  .dept-header {
    margin-top: 50px;
    margin-bottom: 40px;
    color: $orange;
    font-weight: 700;
  }

  .team-member:first-child {
    padding-left: 0px;
  }

  .team-member{
    border: none;

      .member-image {
          position: relative;
      }

      a.button {
        display: none;
      }

      &:hover {
        .overlay {
            opacity: 1;
            background-color: rgba(19,57,109,.85);
            transition: all .3s ease;
        }

        a.button {
          display: block;
          position: absolute;
          left: -40px;
          right: 0;
          width: 60%;
          margin: 0 auto;
          top: 50%;
          transform: translateY(-50%);
          background-color: $white;
          color: $orange;

            i {
              color: $white;
            }
        }
    }
  }
}

/*********************
TESTIMONIAL ARCHIVE
*********************/

.archive-testimonials {
    .video-testimonial {
      padding-top: 0px;
    }

    iframe {
      max-width: 100%;
    }

    .text-testimonial {
      background-color: #F1F1F1;
    }

    .h4 {
      letter-spacing: 5px;
    }
}

.testimonial-row {
    display:none;
}

// a, a:visited {
//     color: #33739E;
//     text-decoration: none;
//     display: block;
//     margin: 10px 0;
// }
a:hover {
    text-decoration: none;
}
#loadMore {
    padding: 10px;
    color: $blue;
    text-align: center;
    letter-spacing: 5px;
    display: block;
    width: 100%;
    font-weight: 800;
    text-transform: uppercase;
    transition: all 600ms ease-in-out;
    -webkit-transition: all 600ms ease-in-out;
    -moz-transition: all 600ms ease-in-out;
    -o-transition: all 600ms ease-in-out;
}

.text-testimonial span.morecontent {
  padding: 0;
}
.text-testimonial .morecontent span {
    display: none;
    text-transform: none;
    padding: 0px;
    font-weight: initial;
    margin-bottom: 0;
    font-size: 1.4rem;
}
.morelink {
    display: block;
}

/*********************
RATINGS CONTAINER
*********************/

.ratings-container {
  background-color: #F1F1F1;
  padding-top: 60px;
  padding-bottom: 50px;

    .h3 {
      margin: 0 auto;
      padding-bottom: 50px;
      text-transform: uppercase;
      letter-spacing: 6px;
    }
    .rating {
      .h5 {
        margin: 20px auto 10px auto;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 5px;
      }
      p {
        text-transform: uppercase;
      }

      img {
        max-height: 34px;
      }
    }
}

/*********************
FLEXIBLE PAGE
*********************/

.flexible-content {
  .flex-header {
    font-weight: 700;
    letter-spacing: 3px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: inline-block;

      &.blue {
        color: $blue;
      }

      &.orange {
        color: $orange;
      }
  }

  .image-layout {
    height: 500px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  blockquote {
    text-align: left;
    font-style: italic;
    max-width: 100%;
    font-weight: 400;
    margin: 0 auto;
    @media only screen and (min-width: 1024px) {
      font-size: 2rem;
    }
  }

  p {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .text-layout {
    padding-top: 15px;
  }

  .button {
    display: block;
    width: fit-content;
    margin-bottom: 15px;
    margin-top: 15px;

      i {
        display: none;
      }

      &.blue {
        background-color: $blue;
          &:hover {
            background-color: $orange;
          }
      }

      &.orange {
        background-color: $orange;
          &:hover {
            background-color: $blue;
          }
      }
  }

}

#menu-sidebar {
  display: block;
}

#menu-sidebar li {
  position: relative;

    a {
      background-color: #f1f1f1;
      padding: 20px;
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 700;

        &:hover {
          background-color: $orange;
          color: $white;
        }
    }

    i {
      position: absolute;
      right: 0px;
      top: 0;
      height: 100%;
      width: 60px;
      text-align: center;

        &:before {
          line-height: 60px;
        }
    }

    li  a{
      background-color: #f1f1f1;
      color: $dark-gray;
      font-weight: 400;
    }
}

#menu-sidebar li.menu-item-has-children ul.sub-menu {
  display: none;
}

#menu-sidebar li.menu-item-has-children.expanded ul.sub-menu {
  display: block;
}

.widget_recent_entries {

  li {
    display: flex;
    margin-bottom: 50px;
  }
   img {
    vertical-align: top;
    margin-right: 10px;
  }
  a {
    display: inline-block;
    text-transform: uppercase;
    line-height: 20px;
    font-size: 1rem;
    font-weight: 600;

      span {
        color: $dark-gray;
        font-style: italic;
        font-size: .9rem;
        font-weight: 400;
        display: block;
      }
  }
}

.widget-read-more {
  background-color: $orange;
  font-size: 1rem;
    i {
      display: none;
    }

}

/*********************
Listings
*********************/

.featured-listing {

  @media only screen and (min-width: 1024px) {
    padding-right: 5px;
  }
}

.listing-header {
  background-color: $blue;

    @media only screen and (min-width: 1024px) {
      padding-left: 20%;
    }

  color: $white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 3rem;
  letter-spacing: 5px;
}

.listing-search-header {
  background-color: $blue;
  color: $white;
  text-align: center;
  padding: 16px 0;
}

div#IDX-quicksearch-50984 {
    border-radius: unset;
    background-color: #EFEFF0;
    border: none;
}

#IDX-quicksearchForm-50984 .IDX-qsLabel {
    display: block;
    float: none;
}

#IDX-quicksearchForm-50984 > .IDX-qsFieldWrap {
    width: 50%;
}

.IDX-qsTextInput {
    width: 100% !important;
}
