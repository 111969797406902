.contact {
  padding-top: 80px;
  padding-bottom: 80px;
}

.contact-content {
  p {
    font-size: 1.5rem;
    letter-spacing: 2px;
    margin-bottom: 60px;
  }

  .address, .email {
    color: $orange;
    font-size: 1.5rem;
    display: inline-block;
    position: relative;
    padding-left: 50px;
    margin-bottom: 30px;

      a {
        color: $orange;
        transition: all .3s ease;

          &:hover {
            color: $blue;
          }
      }
  }

  i {
    width: 20px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2rem;
    text-align: center;
    color: $orange;
  }

  .phone-numbers {
    a {
      background-color: $orange;
      color: $white;
      font-size: 1.9rem;
      letter-spacing: 3px;
      font-weight: 700;
      padding-top: 10px;
      padding-bottom: 9px;
      padding-right: 20px;
      margin-bottom: 20px;
    }

    span {
      background-color: $blue;
      font-weight: 200;
      padding: 10px 20px;
      margin-right: 20px;
    }
  }

}

.form-container {
  background-color: $light-gray;
  padding: 30px 50px;

    .h4 {
      text-transform: uppercase;
      letter-spacing: 7px;
      margin-bottom: 50px;
    }

    input, textarea {
      border: none;
    }

    input#choice_3_6_1 {
      vertical-align: baseline;
    }

    label#label_3_6_1 {
      color: #959595;
      font-size: 1rem;
    }

    .gform_footer.top_label {
      text-align: center;
    }
}
